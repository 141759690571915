<template>
  <div>
    <b-row class="mb-3">
      <b-col cols="12" xl="4" md="6">
        <bot-selector
          @botChanged="
            (botId) => {
              this.botId = botId;
            }
          "
        />
      </b-col>
    </b-row>
    <b-row v-if="trees">
      <b-col>
        <node-tree
          :nodes="trees"
          :intentId="intentId"
          @node-clicked="nodeClicked"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="text-center" style="width: 100%; height: 10vh">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="align-bottom"
          ></b-spinner>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import NodeTree from '@/components/UI/NodeTree/NodeTree';
import BotSelector from '@/components/UI/BotSelector';

export default {
  name: 'IntentMapping',
  components: { NodeTree, BotSelector },
  mixins: [httpclient],
  data() {
    return {
      botId: null,
      intentId: 0,
      trees: [
        {
          label: '1',
          children: [
            {
              label: '2',
              children: [{ label: '3', children: [{ label: '4' }] }],
            },
          ],
        },
      ],
    };
  },
  watch: {
    botId() {
      this.getTrees();
    },
  },
  mounted() {
    this.trees = null;
    if (this.$route.query.intentId) {
      this.intentId = this.$route.query.intentId;
    }
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    if (botId) this.botId = botId;
    this.getTrees();
  },
  methods: {
    getTrees() {
      this.trees = null;

      this.httpclient
        .get('/api/bots/' + this.botId + '/intent-mappings')
        .then((resp) => {
          this.trees = resp.data;
        });
    },
    nodeClicked(e, data) {
      this.$router.push({
        name: 'new-intents',
        query: { intentId: data.intentId, botId: this.botId },
      });
    },
  },
};
</script>

<style scoped>
.tree-container {
  /*display: inline-flex;*/
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100vh - 180px);
}
</style>
